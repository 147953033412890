.modal_header {
  margin-top: 0 !important;
}
.selectable {
  width: 36rem;
}

.selectable
  :global
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2 !important;
}
.selectable :global .DayPicker-Day {
  border-radius: 0 !important;
}
.selectable :global .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.selectable :global .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.form_group {
  margin: 0;
}
.span_style {
  padding: 0 10px;
}

.divider_margin {
  margin-top: 2.7rem !important;
}

.datepicker_input input {
  cursor: pointer;
  width: 36rem !important;
}

.element_width {
  width: 36rem;
}
.center_div {
  display: flex;
  justify-content: center;
}
